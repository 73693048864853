import styled from 'styled-components';

export const AlertWrapper = styled.svg`
  height: 30rem;
  width: 30rem;
  min-height: 30rem;
`;

export const OkWrapper = styled.svg`
  height: 22rem;
  width: 22rem;
  min-height: 22rem;
  margin-bottom: 3.2rem;
`;
