import { Label } from '@facephi/ui-label';
import { useTranslation } from 'react-i18next';
import { Alert, GridWrapper, Layout } from '../components';

type Props = { message?: string };

const Page404 = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <Layout testId="404" noPadding>
      <GridWrapper rowGap="3">
        <Alert />
        <Label fontSize="21" semibold textAlign="center">
          Ooops!
        </Label>
        <Label fontSize="18" textAlign="center">
          {t(message || 'The page you are looking for is not available.')}
          <br />
          {t('Retry later!')}
        </Label>
      </GridWrapper>
    </Layout>
  );
};

export default Page404;
