/* eslint-disable @typescript-eslint/no-non-null-assertion */
export function useVariables() {
  const trackingUrl: string = process.env.REACT_APP_API_TRACKING!;
  const trackingUrlAssets: string = process.env.REACT_APP_API_ASSETS!;
  const authUrl: string = process.env.REACT_APP_AUTH_TRACKING_URL!;
  const clientId: string = process.env.REACT_APP_CLIENTID_TRACKING!;
  const clientSecret: string = process.env.REACT_APP_CLIENTSECRET_TRACKING!;
  const videoApiKey: string = process.env.REACT_APP_VIDEO_API_KEY!;
  const videoApiUrl: string = process.env.REACT_APP_CLOUD_API!;
  const apiClients: string = process.env.REACT_APP_API_CLIENTS!;
  const apiBuilder: string = process.env.REACT_APP_API_BUILDER!;

  return {
    trackingUrl,
    trackingUrlAssets,
    authUrl,
    clientId,
    clientSecret,
    videoApiKey,
    videoApiUrl,
    apiClients,
    apiBuilder,
  };
}
