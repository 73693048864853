import {
  ReactElement,
  ReactNode,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FlowNode, WorkflowDTO, isNode } from '@facephi/sdk-web';

import {
  RequestMethods,
  useClient,
  useFavicon,
  useRequest,
  useVariables,
} from '../hooks';
import { defaultTheme } from '../state/constants';
import { ThemeDto } from '../state/model';

type IProps = {
  children: ReactNode;
};

export type BuilderContextProps = {
  language?: string;
  tenantId?: string;
  theme?: ThemeDto;
  workflow?: WorkflowDTO;
  title?: string;
  videoRecording?: boolean;
  outputUrl?: string;
  apiKey?: string;
};

export const BuilderContext = createContext<BuilderContextProps>({
  language: 'es',
});

export const BuilderProvider = ({ children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const [theme, setTheme] = useState<ThemeDto>();
  const [workflow, setWorkflow] = useState<WorkflowDTO>();
  const [language, setLanguage] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const [outputUrl, setOutputUrl] = useState<string>();
  const [apiKey, setApiKey] = useState<string>();
  const [videoRecording, setVideoRecording] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const { changeFavicon } = useFavicon();

  const { client, preview } = useClient();
  const { apiBuilder } = useVariables();

  const { request } = useRequest();

  const getDefaultData = () => {
    setTheme(defaultTheme);
    changeFavicon(defaultTheme.favicon);
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    if (client) {
      loadInitialData();
    } else {
      getDefaultData();
    }
  }, []);

  const loadInitialData = () => {
    return request(
      `${apiBuilder}/landing/${client}${preview ? '?preview=true' : ''}`,
      {
        method: RequestMethods.get,
      }
    )
      .catch(() => {
        getDefaultData();
        return false;
      })
      .then((response) => {
        if (response) {
          setApiKey(response.apiKey);
          setLanguage(response.configuration.language);
          setTenantId(response.configuration.tenantId);
          setVideoRecording(
            response.configuration.videoRecording ? true : false
          );
          setTheme(response.theme);
          setOutputUrl(response.configuration.outputUrl);

          setWorkflow(
            (response.workflow.definition as WorkflowDTO)?.map((item) => {
              if (!isNode(item)) {
                return item;
              }
              const node = item as FlowNode;
              if (node.data.configuration?.general) {
                node.data.configuration.general.language =
                  response.configuration.language;
              }
              return node;
            })
          );
          setTitle(response.configuration.title);

          document.title = response.configuration.title;

          changeFavicon(response.theme.favicon || defaultTheme.favicon);
        }

        setLoading(false);
        return true;
      });
  };

  return (
    <BuilderContext.Provider
      value={{
        language,
        tenantId,
        theme,
        workflow,
        title,
        videoRecording,
        outputUrl,
        apiKey,
      }}
    >
      {loading
        ? null
        : error
        ? cloneElement(children as ReactElement, { error: true })
        : children}
    </BuilderContext.Provider>
  );
};

export const useBuilder = () => useContext(BuilderContext);
