import React from 'react';
import { Button } from '@facephi/ui-button';
import { FlexContainer, FlexContainerProps } from '@facephi/ui-flex-container';
import { useTranslation } from 'react-i18next';
import { useClient } from '../../hooks';
import { DeviceSize, PREVIOUS_DOMAIN } from '../../state/constants';
import { Header } from '../header';
import { Footer } from '../Styles';

type Props = FlexContainerProps & {
  paddingBig?: boolean;
  noPadding?: boolean;
  noHeader?: boolean;
};

export const Layout = ({
  children,
  className,
  paddingBig = false,
  noPadding = false,
  noHeader = false,
  ...flexProps
}: Props) => {
  const { t, i18n } = useTranslation();
  const { client, preview } = useClient();
  const previousURL = document.referrer;

  const landingRoute = React.useMemo(() => {
    if (
      preview &&
      previousURL &&
      PREVIOUS_DOMAIN.some((domain) => previousURL.includes(domain))
    ) {
      return `${previousURL}landing-builder/${i18n.language}/landings/${client}/customize`;
    }
    return '';
  }, []);

  return (
    <>
      {!noHeader && <Header />}
      <FlexContainer
        as="main"
        className={className}
        flex="1"
        css={{
          overflow: 'auto',
          position: 'relative',
          padding: noPadding ? 0 : paddingBig ? '2.8rem' : '1rem',
          [`@media (min-width: ${DeviceSize.desktop}px)`]: {
            padding: noPadding ? 0 : paddingBig ? '5.6rem' : '3.2rem',
          },
        }}
        {...flexProps}
      >
        {children}
      </FlexContainer>
      {landingRoute && (
        <Footer justifyContent="center" alignItems="center">
          <Button
            size="L"
            variant="text"
            color="primary"
            as="a"
            href={landingRoute}
          >
            {t('Back to the builder')}
          </Button>
        </Footer>
      )}
    </>
  );
};
