/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export enum RequestMethods {
  post = 'post',
  get = 'get',
}

export type RequestOptions = {
  method: RequestMethods;
  data?: any;
};

export function useRequest() {
  const request = (url: string, options: RequestOptions) =>
    axios({
      method: options.method,
      url: `${url}`,
      data: options.data,
    })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });

  return {
    request,
  };
}
