import { FlexContainer } from '@facephi/ui-flex-container';
import { GridContainer } from '@facephi/ui-grid-container';
import styled from 'styled-components';
import { desktopStyles } from '../styles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > button {
    margin-bottom: 2rem;
  }
`;

export const GridWrapper = styled(GridContainer)`
  justify-items: center;
  margin: auto;
  padding: 2rem 1rem;

  ${desktopStyles} {
    padding: 3.2rem;
  }
`;

export const Footer = styled(FlexContainer)`
  padding: 2rem 0;
`;
