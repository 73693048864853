import { FAVICON_ID } from '../state/constants';

export function useFavicon() {
  function changeFavicon(src: string) {
    const link = document.createElement('link'),
      oldLink = document.getElementById('favicon');
    link.id = FAVICON_ID;
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  return {
    changeFavicon,
  };
}
