import { setColorTheme } from '@facephi/ui-theme';
import { AlertWrapper } from './Styles';

export const Alert = () => {
  return (
    <AlertWrapper
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_653_70429)">
        <path
          d="M231.636 79.3494C221.161 69.8423 198.707 60.4036 153.144 80.9457C130.933 90.9587 104.788 89.574 83.6942 101.763C34.053 130.447 45.3266 184.94 45.3266 184.94C47.0405 197.373 51.5964 209.309 58.2767 219.921H193.558C201.898 209.082 208.83 197.084 214.974 184.833C224.469 165.897 233.453 146.426 238.276 125.8C244.939 97.2989 234.919 82.0543 231.637 79.3494H231.636Z"
          fill="#EAEEF6"
        />
        <path
          d="M125.557 247.317C114.84 247.317 104.755 246.262 97.1632 244.347C87.0938 241.807 84.9797 238.731 84.9797 236.599C84.9797 234.467 87.0938 231.39 97.1632 228.851C104.756 226.936 114.84 225.881 125.557 225.881C136.274 225.881 146.359 226.936 153.951 228.851C164.02 231.39 166.134 234.467 166.134 236.599C166.134 238.731 164.02 241.807 153.951 244.347C146.358 246.262 136.274 247.317 125.557 247.317Z"
          fill="#7D879C"
        />
        <path
          d="M139.092 245.387L138.759 205.393C138.859 204.808 139.352 204.369 139.947 204.343C142.723 204.222 146.279 202.705 148.125 201.303C150.471 199.519 151.993 197.487 153.043 194.733L147.743 160.478L146.536 152.683L127.809 155.58L107.702 244.96C107.702 244.96 124.181 246.942 139.092 245.387Z"
          fill="white"
        />
        <path
          d="M139.489 246.544C139.138 246.544 138.85 246.262 138.844 245.91L138.113 205.405C138.113 205.365 138.116 205.325 138.122 205.285C138.273 204.405 139.028 203.737 139.919 203.698C142.567 203.582 145.972 202.129 147.734 200.789C149.982 199.081 151.434 197.142 152.439 194.503C152.566 194.17 152.939 194.004 153.272 194.13C153.605 194.257 153.771 194.63 153.645 194.963C152.548 197.839 150.965 199.953 148.513 201.816C146.593 203.276 142.874 204.861 139.973 204.987C139.702 204.999 139.47 205.192 139.403 205.453L140.132 245.886C140.138 246.242 139.854 246.536 139.498 246.542C139.494 246.542 139.49 246.542 139.487 246.542L139.489 246.544Z"
          fill="#7D879C"
        />
        <path
          d="M126.46 37.4392C123.687 37.8689 121.504 39.1891 121.642 40.0783L146.493 214.533C146.631 215.422 148.992 215.795 151.765 215.365C154.539 214.936 156.676 213.866 156.539 212.977L132.312 38.2419C132.174 37.3527 129.235 37.0108 126.461 37.4405L126.46 37.4392Z"
          fill="#CCD4E5"
        />
        <path
          d="M157.178 212.889L132.949 38.1424C132.689 36.4622 128.77 36.4286 126.362 36.8016C123.6 37.23 120.767 38.6418 121.005 40.1698L145.857 214.632C145.937 215.148 146.381 215.819 148.042 216.081C148.499 216.154 149.011 216.19 149.56 216.19C150.282 216.19 151.067 216.128 151.866 216.004C153.272 215.786 154.575 215.401 155.535 214.921C157.039 214.169 157.26 213.395 157.181 212.889H157.178ZM151.667 214.729C148.908 215.157 147.323 214.685 147.128 214.415L122.282 39.9982C122.368 39.6123 123.942 38.4831 126.558 38.0766C129.2 37.6675 131.331 38.0405 131.679 38.3902L155.898 213.055C155.797 213.369 154.429 214.301 151.666 214.729H151.667Z"
          fill="#7D879C"
        />
        <path
          d="M203.737 122.759C203.737 122.759 203.737 122.749 203.737 122.743L192.306 38.646C192.283 38.4769 192.194 38.3233 192.057 38.2188C191.922 38.1156 191.749 38.0704 191.58 38.0923L67.1781 54.9991H67.1755C67.1639 55.0017 67.1523 55.0056 67.1394 55.0082C67.1097 55.0146 67.08 55.0198 67.0503 55.0301H67.0477C67.0335 55.0366 67.0206 55.0443 67.0064 55.0508C66.9819 55.0611 66.9574 55.0714 66.9354 55.0843C66.9199 55.0933 66.9057 55.1063 66.8903 55.1166C66.8709 55.1308 66.8503 55.1437 66.8335 55.1592C66.8193 55.1721 66.8064 55.1876 66.7935 55.203C66.7793 55.2185 66.7638 55.2314 66.7522 55.2482C66.7509 55.2495 66.7496 55.2521 66.7483 55.2534C66.7367 55.2689 66.7276 55.2869 66.7173 55.3024C66.7057 55.3231 66.6915 55.3424 66.6825 55.3631C66.6825 55.3656 66.6799 55.3669 66.6786 55.3695L65.2538 58.4874C65.2021 58.5996 65.1841 58.7235 65.2021 58.8448L76.7661 141.863C76.8113 142.185 77.0875 142.419 77.405 142.419C77.4346 142.419 77.4643 142.416 77.494 142.412L199.831 125.468C199.916 125.456 199.998 125.427 200.073 125.383L203.426 123.384H203.429C203.461 123.363 203.49 123.341 203.518 123.317C203.526 123.31 203.532 123.304 203.54 123.296C203.563 123.274 203.584 123.252 203.603 123.228C203.607 123.223 203.611 123.219 203.615 123.214C203.637 123.184 203.656 123.153 203.673 123.121C203.677 123.113 203.681 123.104 203.684 123.096C203.697 123.069 203.708 123.042 203.715 123.014C203.718 123.004 203.722 122.995 203.723 122.988C203.732 122.953 203.739 122.917 203.741 122.879C203.741 122.872 203.741 122.863 203.741 122.854C203.741 122.837 203.744 122.819 203.743 122.802C203.743 122.786 203.74 122.772 203.737 122.757V122.759Z"
          fill="#7D879C"
        />
        <path
          d="M149.068 156.235C147.716 156.77 145.5 158.372 143.79 159.763C139.799 162.83 141.463 168.292 143.605 168.584C144.747 168.71 146.243 168.039 147.235 167.629C150.05 166.462 151.115 166.055 153.722 164.407C156.378 162.729 160.262 157.783 158.553 154.437C157.233 152.228 157.07 150.051 148.249 150.506L149.068 156.235Z"
          fill="white"
        />
        <path
          d="M156.968 161.688C157.271 161.269 161.866 162.803 161.696 168.249C161.57 172.265 153.761 177.254 152.401 177.827C151.041 178.402 149.538 178.612 148.054 178.817C146.053 179.095 144.053 179.371 142.052 179.648C141.389 179.74 140.676 179.825 140.052 179.531C139.206 179.132 138.784 178.154 138.693 177.254C138.461 174.988 139.838 172.88 141.715 171.681C143.593 170.482 145.253 170.366 147.531 170.145L150.612 167.457L150.476 166.467C150.476 166.467 154.792 164.7 156.969 161.69L156.968 161.688Z"
          fill="white"
        />
        <path
          d="M159.742 172.153C160.966 173.028 162.143 174.62 162.049 177.626C161.924 181.642 154.115 186.631 152.754 187.204C151.395 187.778 149.892 187.989 148.407 188.194C146.407 188.471 144.974 188.811 142.375 189.368C141.719 189.509 140.99 189.336 140.406 188.909C139.652 188.356 139.096 187.292 139.004 186.392C138.772 184.126 139.849 182.243 141.727 181.044C143.605 179.845 145.777 179.039 148.054 178.818L159.742 172.154V172.153Z"
          fill="white"
        />
        <path
          d="M150.821 187.84C149.268 188.216 147.021 188.621 145.562 189.27C144.151 189.897 142.976 190.722 142.517 192.205C142.215 193.18 142.264 194.707 143.747 195.699C145.323 196.648 148.296 195.751 149.058 195.628C151.962 195.159 152.793 194.774 154.937 193.792C157.656 192.446 158.749 190.606 159.851 188.637C161.545 185.566 161.117 182.664 160.131 181.49L150.821 187.842V187.84Z"
          fill="white"
        />
        <path
          d="M149.457 162.999C148.983 162.247 148.012 161.931 147.153 162.239C145.758 162.739 143.686 163.793 143.919 165.664C144.139 167.437 144.98 167.821 144.98 167.821C144.98 167.821 145.869 168.352 149.849 166.342C150.641 165.942 150.101 164.024 149.457 162.999Z"
          fill="#EAEEF6"
        />
        <path
          d="M143.917 169.246C143.793 169.246 143.671 169.24 143.551 169.227C143.546 169.227 143.54 169.227 143.535 169.225C142.402 169.07 141.39 167.916 140.957 166.285C140.408 164.223 140.764 161.294 143.407 159.258C145.21 157.791 148.209 156.021 149.588 155.474C149.92 155.344 150.294 155.505 150.426 155.837C150.557 156.169 150.395 156.543 150.063 156.674C148.793 157.176 145.902 158.89 144.215 160.265C144.21 160.268 144.206 160.272 144.201 160.276C142.055 161.927 141.762 164.291 142.205 165.954C142.494 167.041 143.109 167.859 143.704 167.946C144.599 168.04 145.916 167.49 146.789 167.125L147.472 166.841C149.956 165.814 150.983 165.388 153.396 163.864C155.859 162.306 159.472 157.671 158.006 154.75C156.792 152.72 155.519 150.631 148.067 151.109C147.709 151.136 147.404 150.862 147.381 150.506C147.358 150.15 147.628 149.844 147.984 149.821C156.247 149.29 157.843 151.962 159.126 154.108C159.134 154.119 159.14 154.132 159.147 154.145C161.076 157.922 156.878 163.19 154.086 164.954C151.58 166.538 150.448 167.007 147.966 168.033L147.287 168.315C146.333 168.714 145.061 169.245 143.919 169.245L143.917 169.246Z"
          fill="#7D879C"
        />
        <path
          d="M146.599 175.352C146.098 174.569 144.999 174.22 144.028 174.487C142.563 174.89 140.59 175.694 140.705 177.285C140.813 178.778 141.539 179.225 141.539 179.225C141.539 179.225 142.331 179.825 146.75 178.707C147.78 178.447 147.296 176.441 146.599 175.352Z"
          fill="#EAEEF6"
        />
        <path
          d="M141.087 180.381C140.661 180.381 140.208 180.319 139.777 180.115C138.829 179.669 138.184 178.625 138.05 177.322C137.812 175.004 139.115 172.576 141.367 171.137C143.296 169.905 145.012 169.74 147.184 169.531L147.222 169.527C147.687 169.035 148.998 167.71 150.261 167.034C150.574 166.866 150.965 166.983 151.134 167.297C151.302 167.611 151.185 168.002 150.871 168.171C149.538 168.886 148.025 170.559 148.01 170.576C147.901 170.696 147.753 170.772 147.592 170.788L147.308 170.815C145.23 171.015 143.729 171.159 142.061 172.225C140.216 173.404 139.144 175.352 139.333 177.19C139.4 177.845 139.696 178.652 140.325 178.949C140.764 179.155 141.304 179.102 141.962 179.011L147.963 178.18C149.387 177.982 150.859 177.778 152.149 177.234C153.624 176.61 160.939 171.761 161.049 168.23C161.192 163.668 158.265 162.767 157.016 162.383C156.914 162.352 156.826 162.325 156.756 162.3C156.419 162.184 156.24 161.816 156.356 161.479C156.472 161.143 156.839 160.963 157.177 161.079C157.236 161.1 157.31 161.122 157.396 161.149C158.662 161.539 162.514 162.725 162.339 168.27C162.2 172.72 154.06 177.827 152.651 178.422C151.205 179.033 149.647 179.248 148.141 179.457L142.14 180.288C141.831 180.331 141.469 180.381 141.087 180.381Z"
          fill="#7D879C"
        />
        <path
          d="M147.602 184.908C147.153 184.114 146.146 183.637 145.188 183.797C143.54 184.073 140.844 184.874 141.198 186.96C141.34 187.799 141.63 188.346 141.909 188.694C142.205 189.063 142.696 189.229 143.169 189.116C144.097 188.897 145.994 188.45 147.861 188.017C148.652 187.832 148.185 185.939 147.601 184.907L147.602 184.908Z"
          fill="#EAEEF6"
        />
        <path
          d="M149.035 192.087C148.761 191.393 147.971 191.016 147.177 191.179C145.929 191.434 144.115 192.043 144.022 193.525C144.104 195.017 144.836 195.293 144.836 195.293C144.836 195.293 145.388 195.935 148.732 194.859C149.49 194.615 149.406 193.029 149.035 192.087Z"
          fill="#EAEEF6"
        />
        <path
          d="M141.909 190.062C141.253 190.062 140.591 189.843 140.025 189.429C139.16 188.796 138.476 187.574 138.362 186.458C138.116 184.054 139.216 181.882 141.381 180.5C143.414 179.201 145.701 178.398 147.992 178.174C148.348 178.141 148.662 178.399 148.696 178.754C148.731 179.109 148.472 179.423 148.117 179.458C146.027 179.662 143.938 180.398 142.076 181.586C140.311 182.713 139.449 184.396 139.647 186.325C139.724 187.081 140.214 187.967 140.788 188.387C141.225 188.707 141.768 188.838 142.241 188.737C142.655 188.648 143.04 188.565 143.401 188.487C145.336 188.067 146.615 187.791 148.32 187.555C149.743 187.357 151.216 187.154 152.505 186.609C153.98 185.986 161.295 181.136 161.405 177.605C161.476 175.352 160.79 173.694 159.367 172.677C159.077 172.469 159.01 172.066 159.218 171.777C159.424 171.487 159.828 171.42 160.117 171.628C161.912 172.91 162.778 174.935 162.695 177.646C162.555 182.096 154.415 187.204 153.006 187.799C151.56 188.409 150.003 188.625 148.496 188.834C146.839 189.063 145.58 189.336 143.673 189.749C143.311 189.827 142.926 189.91 142.511 189.999C142.313 190.042 142.112 190.062 141.909 190.062Z"
          fill="#7D879C"
        />
        <path
          d="M145.693 196.777C144.879 196.777 144.07 196.644 143.416 196.25C143.407 196.245 143.4 196.24 143.391 196.233C141.683 195.093 141.502 193.304 141.903 192.012C142.482 190.14 144.023 189.248 145.301 188.679C146.482 188.154 148.092 187.794 149.513 187.476C149.926 187.383 150.317 187.297 150.67 187.212C151.016 187.128 151.365 187.341 151.449 187.686C151.533 188.032 151.32 188.382 150.974 188.466C150.61 188.554 150.214 188.643 149.795 188.736C148.433 189.04 146.888 189.385 145.825 189.858C144.276 190.548 143.471 191.306 143.135 192.394C143.009 192.805 142.704 194.213 144.094 195.153C145.207 195.81 147.371 195.331 148.412 195.102C148.638 195.051 148.818 195.013 148.956 194.989C151.764 194.535 152.549 194.175 154.649 193.212L154.66 193.207C157.283 191.905 158.303 190.083 159.289 188.319C160.857 185.475 160.437 182.854 159.637 181.901C159.407 181.629 159.443 181.221 159.716 180.993C159.989 180.763 160.396 180.799 160.624 181.071C161.25 181.817 161.621 183.008 161.64 184.34C161.656 185.4 161.456 187.059 160.415 188.946C159.38 190.795 158.21 192.888 155.222 194.367C155.217 194.37 155.21 194.373 155.204 194.375L155.183 194.384C153.03 195.37 152.14 195.779 149.158 196.262C149.058 196.279 148.886 196.316 148.687 196.36C147.935 196.526 146.806 196.775 145.689 196.775L145.693 196.777Z"
          fill="#7D879C"
        />
        <path
          d="M132 146.88C132 146.88 132.957 147.217 134.085 148.453C134.582 148.998 135.443 148.978 135.902 148.403C136.839 147.227 138.178 145.489 138.467 144.805C138.922 143.727 138.207 142.628 136.939 142.283C136.939 142.283 135.174 143.264 132 146.881V146.88Z"
          fill="white"
        />
        <path
          d="M133.336 164.342C134.232 161.318 136.485 157.782 137.977 156.062C139.731 154.04 141.496 152.001 142.765 149.62C143.535 148.174 144.125 146.522 143.809 144.779C143.493 143.035 142.011 141.239 140.245 141.042C139.148 140.919 138.169 141.404 137.303 141.94C135.317 143.167 133.582 144.721 131.886 146.297C127.405 150.461 123.04 154.978 120.849 160.8C118.886 166.017 115.588 178.302 115.556 180.785C115.552 181.037 114.716 187.271 114.44 189.417C112.611 203.613 108.603 232.77 107.638 245.149L130.335 240.946L137.604 208.573C137.234 205.923 133.336 164.342 133.336 164.342Z"
          fill="white"
        />
        <path
          d="M132 146.88C132 146.88 132.957 147.217 134.085 148.453C134.582 148.998 135.443 148.978 135.902 148.403C136.839 147.227 138.178 145.489 138.467 144.805C138.922 143.727 138.207 142.628 136.939 142.283C136.939 142.283 135.174 143.264 132 146.881V146.88Z"
          fill="#EAEEF6"
        />
        <path
          d="M107.629 245.933C107.608 245.933 107.588 245.933 107.566 245.931C107.211 245.896 106.951 245.581 106.985 245.226C108.599 228.546 113.212 190.395 114.917 180.696C115.455 175.57 118.381 165.528 120.246 160.573C122.563 154.419 127.242 149.733 131.449 145.824C133.111 144.28 134.906 142.664 136.965 141.391C137.89 140.82 139.01 140.254 140.319 140.402C142.46 140.642 144.098 142.758 144.445 144.665C144.739 146.284 144.386 147.955 143.335 149.925C142.027 152.379 140.216 154.467 138.464 156.486C136.899 158.292 134.786 161.723 133.954 164.527C133.852 164.869 133.493 165.064 133.151 164.962C132.809 164.86 132.614 164.502 132.716 164.16C133.6 161.181 135.834 157.548 137.489 155.641C139.194 153.676 140.955 151.643 142.196 149.318C143.103 147.618 143.414 146.213 143.174 144.895C142.917 143.483 141.697 141.855 140.174 141.683C139.259 141.581 138.418 142.01 137.644 142.488C135.684 143.7 133.943 145.267 132.327 146.769C128.23 150.577 123.673 155.135 121.455 161.028C119.612 165.925 116.721 175.836 116.2 180.852C116.199 180.868 116.196 180.882 116.193 180.897C114.495 190.537 109.885 228.677 108.27 245.35C108.238 245.683 107.957 245.933 107.629 245.933Z"
          fill="#7D879C"
        />
        <path
          d="M191.114 39.4577L67.9917 56.1904L79.249 139.008L202.372 122.275L191.114 39.4577Z"
          fill="white"
        />
        <path
          d="M102.981 78.4795C103.938 85.5179 99.7225 91.018 92.1427 92.0491L83.2426 93.2583L80.0134 69.5054L88.9136 68.2962C96.4934 67.2664 102.02 71.4089 102.981 78.4808V78.4795ZM91.0895 87.091C95.4544 86.4974 97.6859 83.6441 97.0935 79.2796C96.4998 74.9152 93.5791 72.6929 89.213 73.2853L86.4717 73.6582L88.3483 87.464L91.0895 87.091Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M119.112 88.6242C112.377 89.5392 106.211 85.2431 105.24 78.1028C104.269 70.9637 109.071 65.2107 115.805 64.2957C122.573 63.3756 128.634 67.6523 129.604 74.7926C130.575 81.9317 125.846 87.7092 119.112 88.6242ZM118.394 83.3461C122.185 82.8312 124.282 79.7546 123.717 75.5927C123.142 71.3625 120.308 69.0254 116.517 69.5403C112.66 70.0643 110.587 73.0685 111.161 77.2988C111.727 81.4606 114.536 83.87 118.394 83.3461Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M152.466 59.658L155.695 83.4109L149.908 84.1968L138.238 70.8608L140.23 85.5119L134.443 86.2978L131.214 62.5449L137.001 61.759L148.68 75.1621L146.679 60.4427L152.466 59.6567V59.658Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M157.457 68.2163L157.984 58.9093L163.905 58.104L161.347 67.6872L157.456 68.2163H157.457Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M166.005 57.8189L184.38 55.3218L185.009 59.9572L178.715 60.8128L181.313 79.9303L175.526 80.7162L172.928 61.5988L166.633 62.4544L166.004 57.8189H166.005Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M96.305 113.184L92.7184 113.672L93.8774 122.198L88.0902 122.984L84.8611 99.2309L94.2349 97.9572C100.056 97.1661 103.411 100.053 104.005 104.417C104.538 108.342 102.362 112.36 96.305 113.184ZM95.239 108.641C97.5401 108.329 98.3649 107.045 98.1171 105.217C97.8693 103.39 96.7309 102.373 94.4298 102.686L91.2819 103.113L92.0911 109.068L95.239 108.641Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M123.425 113.909L114.558 115.114L113.708 119.503L107.651 120.326L113.017 95.4043L119.717 94.4932L131.542 117.078L125.416 117.911L123.425 113.907V113.909ZM117.203 101.348L115.475 110.44L121.329 109.645L117.203 101.35V101.348Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M152.171 90.0838L155.4 113.837L149.613 114.623L137.943 101.287L139.934 115.938L134.147 116.724L130.918 92.9707L136.705 92.1847L148.384 105.588L146.384 90.8684L152.171 90.0825V90.0838Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M156.368 89.5135L162.155 88.7275L165.384 112.48L159.597 113.266L156.368 89.5135Z"
          fill={setColorTheme('primary100')}
        />
        <path
          d="M177.279 86.3955C182.93 85.6276 187.626 88.2293 189.679 93.1874L183.317 94.052C182.06 92.155 180.128 91.3832 177.929 91.6826C174.376 92.1653 172.298 95.136 172.869 99.3314C173.439 103.527 176.235 105.836 179.788 105.353C181.987 105.053 183.643 103.795 184.349 101.631L190.71 100.766C190.056 106.094 186.22 109.819 180.568 110.588C173.564 111.541 167.947 107.238 166.981 100.132C166.015 93.026 170.274 87.3466 177.28 86.3942L177.279 86.3955Z"
          fill={setColorTheme('primary100')}
        />
      </g>
      <defs>
        <clipPath id="clip0_653_70429">
          <rect
            width="196.285"
            height="210.707"
            fill="white"
            transform="translate(44.2271 36.6094)"
          />
        </clipPath>
      </defs>
    </AlertWrapper>
  );
};
