import { useBuilder } from '../../providers';
import { HeaderStyles } from './Styles';

export const Header = () => {
  const { theme } = useBuilder();

  return (
    <HeaderStyles
      forwardedAs="header"
      alignItems="center"
      justifyContent="center"
    >
      {theme && <img src={theme.logo} alt="logo" />}
    </HeaderStyles>
  );
};
