import { Suspense, useEffect } from 'react';
import { FlexContainer } from '@facephi/ui-flex-container';
import { Spinner } from '@facephi/ui-spinner';
import {
  InphiniteThemeProvider,
  createTheme,
  setColorTheme,
} from '@facephi/ui-theme';
import WebFont from 'webfontloader';
import Error404 from '../pages/404';
import { TranslationProvider, useBuilder } from '../providers';
import { Routes } from '../routes/Routes';
import { FAVICON_ID, defaultFont } from '../state/constants';
import { LandingGlobalStyles } from '../styles';

type IProps = {
  error?: boolean;
};

export const Main = ({ error = false }: IProps) => {
  const { language, title, theme } = useBuilder();
  const innerFont = theme?.fontName || defaultFont;

  const setFont = (font: string) => {
    WebFont.load({
      google: {
        families: [font],
      },
    });
  };

  const landingTheme = createTheme('landing-theme', {
    colors: {
      // header
      primary100: theme ? theme.primaryColor : setColorTheme('primary100'),
      // button
      primary400: theme ? theme.secondaryColor : setColorTheme('primary400'),
      // button hover
      primary500: theme ? theme.terciaryColor : setColorTheme('primary500'),
    },
  });

  useEffect(() => {
    const favicon = document.getElementById(FAVICON_ID) as HTMLLinkElement;

    if (theme) {
      favicon.href = theme.favicon || '/favicon.ico';
      setFont(innerFont);
    }

    if (title) {
      document.title = title;
    }
  }, []);

  return (
    <InphiniteThemeProvider>
      <FlexContainer
        className={landingTheme}
        flexDirection="column"
        justifyContent="center"
        flex="1"
        style={{ overflow: 'auto' }}
      >
        <TranslationProvider language={language}>
          <LandingGlobalStyles font={innerFont} />
          <Suspense fallback={<Spinner />}>
            {error ? <Error404 /> : <Routes />}
          </Suspense>
        </TranslationProvider>
      </FlexContainer>
    </InphiniteThemeProvider>
  );
};
