import { FlexContainer } from '@facephi/ui-flex-container';
import { setColorTheme } from '@facephi/ui-theme';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const HeaderStyles = styled(FlexContainer)`
  min-height: 7rem;
  height: 7rem;
  width: 100%;
  padding: 0 1.6rem;
  background-color: ${setColorTheme('primary100')};

  img {
    max-height: 5rem;
    object-fit: contain;
  }

  ${desktopStyles} {
    padding: 0 18rem;
  }
`;
