import { FlexContainer } from '@facephi/ui-flex-container';
import styled from 'styled-components/macro';

export const BrowserContainer = styled(FlexContainer)`
  height: 45rem;
  background-color: ${(props) => props.theme.color.greys.white};
  border-radius: 0.8rem;
  padding: 4.8rem 3.6rem;
  box-shadow: 0 5px 10px 0 ${(props) => props.theme.color.greys[100]};
  border: solid 1px ${(props) => props.theme.color.greys[100]};
  a {
    color: ${(props) => props.theme.color.primary[400]};
  }
  & > *:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const BrowserImage = styled.img`
  width: 3.2rem;
  height: 3.2rem;
`;

export const BrowserListContainer = styled(FlexContainer)`
  & > *:not(:last-child) {
    margin-right: 2rem;
  }
  & > * {
    margin-bottom: 1.6rem;
  }
`;
