import React from 'react';
import { createRoot } from 'react-dom/client';
import { Main } from './components';
import { BuilderProvider } from './providers';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <BuilderProvider>
      <Main />
    </BuilderProvider>
  </React.StrictMode>
);
